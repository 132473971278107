import React from "react";

function Home() {
    document.title = "TriEvents";
    return (
        <section className="hero is-fullheight" style={{ backgroundColor: 'white'}}>
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="columns">
                        <div className="column">&nbsp;</div>
                        <div className="column">
                            <img src="/branding/TriEvents_Logo.png"></img><br/><br/>
                            <p style={{color: "black"}}>TriEvents is the all-in-one event management platform built for
                                student organizations. From
                                ticketing and registrations to real-time analytics, we make it easy for student
                                governments,
                                clubs, and campus groups to host seamless events. Designed with student needs in mind,
                                TriEvents
                                helps you sell tickets, track attendance, and engage your community—without the
                                hassle. </p>
                            <br/>
                            <a className="button is-black is-medium is-fullwidth" href="https://tricode.cloud">Learn More</a>

                        </div>
                        <div className="column">&nbsp;</div>
                    </div>

                    <br/><br/>
                </div>
            </div>
        </section>

    );
}

export default Home;
