import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'bulma/css/bulma.min.css';

const ValidateTicket = () => {
    const { uuid } = useParams();
    const [ticket, setTicket] = useState(null);
    const [error, setError] = useState(null);
    const [burnMessage, setBurnMessage] = useState(null);

    useEffect(() => {
        const fetchTicket = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/tickets/validate/${uuid}`);
                if (response.status === 404) {
                    setError('That ticket does not exist.');
                    return;
                }
                const data = await response.json();
                if (!data.valid) {
                    setError('Ticket has already been used.');
                    return;
                }
                setTicket(data.ticket);
            } catch (err) {
                setError('An error occurred while validating the ticket. ' + err);
            }
        };

        fetchTicket();
    }, [uuid]);

    const burnTicket = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/tickets/burn/${uuid}`, {
                method: 'GET',
            });
            const data = await response.json();
            if (data.burned) {
                setBurnMessage('Ticket has been successfully burned.');
                setTicket({ ...ticket, ticket_burned: true });
            } else {
                setError('Failed to burn the ticket.');
            }
        } catch (err) {
            setError('An error occurred while burning the ticket. ' + err);
        }
    };

    return (
        <section className="section">
            <div className="container">
                {error ? (
                    <div className="notification is-danger">
                        {error}
                    </div>
                ) : ticket ? (
                    <div className="box">
                        <h1 className="title is-1">Ticket Information</h1>
                        <h2 className="subtitle is-2"><strong>Customer Name:</strong> {ticket.customer_name}</h2>
                        <h3 className="subtitle is-3"><strong>Ticket
                            Status:</strong> {ticket.ticket_burned ? 'Used' : 'Valid'}</h3>
                        <hr/>
                        <p><strong>UUID:</strong> {ticket.uuid}</p>
                        <p><strong>Organization:</strong> {ticket.org_name}</p>
                        <p><strong>Event UUID:</strong> {ticket.event_uuid}</p>
                        <p><strong>Customer Email:</strong> {ticket.customer_email}</p>
                        <p><strong>Created At:</strong> {new Date(ticket.createdAt).toLocaleString()}</p>
                        <p><strong>Updated At:</strong> {new Date(ticket.updatedAt).toLocaleString()}</p>
                        <br></br>
                        {!ticket.ticket_burned && (
                            <button className="button is-large is-danger is-fullwidth" onClick={burnTicket}>Burn Ticket</button>

                )}
                {burnMessage && (
                    <div className="notification is-success">
                                {burnMessage}
                            </div>
                        )}
                    </div>
                ) : (
                    <div>Loading...</div>
                )}
            </div>
        </section>
    );
};

export default ValidateTicket;